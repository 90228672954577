import React, { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Navigate,
} from "react-router-dom";

import { HomePage } from "./pages/Home";
import { PageTwo } from "./pages/Page2";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import WaLogo from "./components/WaLogo";
import Blog from "./pages/Blog";
import { TentangKami } from "./pages/TentangKami";
import { KisiMobile } from "./pages/KisiMobile";
import { KisiMobileDelete } from "./pages/KisiMobile/DeleteAccount";
import { LokasiIndonesia } from "./pages/LokasiIndonesia";
import { LokasiGlobal } from "./pages/LokasiGlobal";
// import { ProfilPerusahaan } from "./pages/ProfilPerusahaan";
import { Koins } from "./pages/Koins";
import { Panduan } from "./pages/Panduan";
import { ArtikelById } from "./pages/ArtikelById";
import { StrukturOrganisasi } from "./pages/StrukturOrganisasi";
import { Lokasi } from "./pages/Lokasi";
import { LaporanTahunan } from "./pages/LaporanTahunan";

import { NotFound } from "./pages/NotFound";
import { DisclaimerMargin } from "./pages/Etc/DisclaimerMargin";
import { Disclaimer } from "./pages/Disclaimer";
import { Signature } from "./pages/Signature";
import { Promo } from "./pages/Promo";
import { Layanan } from "./pages/Layanan";
import { Products } from "./pages/Products";
import { ConfirmDelete } from "./pages/ConfirmDelete";
import { SmoothScroll } from "./components/SmoothScroll";

import { KantorCabang } from "./pages/KantorCabang";
import { Saham } from "./pages/Products/Saham";
import { CappingList } from "./pages/Announcement/CappingList";
import { Margin } from "./pages/Products/Margin";
import { Reksadana } from "./pages/Products/Reksadana";
import { Etf } from "./pages/Products/Etf";
import { Syariah } from "./pages/Products/Syariah";
import { CabangById } from "./pages/KantorCabang/CabangById";
import FaqWarrant from "./pages/Products/WaranTerstruktur/Faq";
import Pengumuman from "./pages/Products/WaranTerstruktur/Pengumuman";
import PengumumanById from "./pages/Products/WaranTerstruktur/PengumumanById";
import Dokumen from "./pages/Products/WaranTerstruktur/Dokumen";
import { WaranTerstruktur } from "./pages/Products/WaranTerstruktur";
import { Bonds } from "./pages/Products/Bonds";
import redirect from "./assets/redirect.gif";
import { ResetKoinsPassword } from "./pages/Koins/ResetKoinsPassword";
const App = () => {
  function openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/confirmation" exact element={<ConfirmDelete />} />
        <Route path="/blog/:page" exact element={<Blog />} />
        <Route
          path="/struktur_organisasi"
          exact
          element={<StrukturOrganisasi />}
        />
        <Route
          path="/contact"
          exact
          element={
            <External link="https://api.whatsapp.com/send/?phone=628151881911&text&type=phone_number&app_absent=0" />
          }
        />
        <Route
          path="/open-new-account"
          exact
          element={<Navigate to="/kisimobile" />}
        />

        <Route path="/laporan_tahunan" exact element={<LaporanTahunan />} />
        <Route path="/lokasi/:page" exact element={<Lokasi />} />
        <Route path="/tentangkami/:page" exact element={<TentangKami />} />
        <Route path="/kisimobile" exact element={<KisiMobile />} />
        <Route
          path="/kisimobile/delete-account"
          exact
          element={<KisiMobileDelete />}
        />
        <Route path="/signature-spouse/:token" exact element={<Signature />} />
        <Route path="/koins" exact element={<Koins />} />
        <Route path="/disclaimer-margin" exact element={<DisclaimerMargin />} />
        <Route path="/panduan/:page" exact element={<Panduan />} />

        <Route
          path="/koins/reset-password"
          exact
          element={<ResetKoinsPassword />}
        />
        <Route path="/disclaimer" exact element={<Disclaimer />} />
        <Route path="/privacy" exact element={<Disclaimer />} />
        {/* <Route path="/produk/:page" exact element={<Products />} /> */}
        {/* <Route path="/produk/:page" exact element={<Products />} /> */}

        <Route path="/produk/*" exact element={<Products />}>
          <Route path="saham" exact element={<Saham />} />
          <Route path="margin" exact element={<Margin />} />
          <Route path="reksadana" exact element={<Reksadana />} />
          <Route path="syariah" exact element={<Syariah />} />
          <Route path="etf" exact element={<Etf />} />          <Route path="bonds" exact element={<Bonds />} />

          {/* <Route
            path="waran_terstruktur"
            exact
            element={<WaranTerstruktur />}
          /> */}

          <Route path="waran-terstruktur/*" exact>
            <Route path="" exact element={<WaranTerstruktur />} />
            <Route path="faq/*" exact element={<FaqWarrant />} />
            <Route
              path="pengumuman/penerbitan"
              exact
              element={
                <Pengumuman
                  category={{ title: "penerbitan", category_id: 1 }}
                />
              }
            />
            <Route
              path="pengumuman/kadaluwarsa"
              exact
              element={
                <Pengumuman
                  category={{ title: "kadaluwarsa", category_id: 2 }}
                />
              }
            />
            <Route
              path="pengumuman/perubahan"
              exact
              element={
                <Pengumuman category={{ title: "perubahan", category_id: 3 }} />
              }
            />
            <Route
              path="pengumuman/penyelesaian"
              exact
              element={
                <Pengumuman
                  category={{ title: "penyelesaian", category_id: 4 }}
                />
              }
            />
            <Route
              path="pengumuman/etc"
              exact
              element={
                <Pengumuman
                  category={{ title: "Pengumuman Lainnya", category_id: 7 }}
                />
              }
            />
            <Route
              path="pengumuman/:id"
              exact
              element={<PengumumanById category={"etc"} />}
            />
            <Route
              path="pengumuman/:category/:id"
              exact
              element={<PengumumanById category={"etc"} />}
            />
            <Route
              path="dokumen/prospektus-dasar"
              exact
              element={
                <Dokumen
                  category={{ title: "Prospektus Dasar", category_id: 6 }}
                />
              }
            />
            <Route
              path="dokumen/term-sheet"
              exact
              element={
                <Dokumen category={{ title: "Term Sheet", category_id: 1 }} />
              }
            />
            <Route
              path="dokumen/aksi-korporasi"
              exact
              element={
                <Dokumen
                  category={{ title: "Aksi Korporasi", category_id: 8 }}
                />
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>
          {/* <Route path="development" Component={ Development } />
               <Route path="consult" Component={ Consult } />               */}
        </Route>
        <Route path="/cabang/*" exact>
          <Route path="" exact element={<KantorCabang />} />
          <Route path=":id" exact element={<CabangById />} />
          
        </Route>
        <Route path="/capping/*" exact>

          <Route path=":type" exact element={<CappingList />} />
        </Route>
        <Route path="/artikel/:news_id" exact element={<ArtikelById />} />
        <Route path="/blog/promo/:page" exact element={<Promo />} />
        <Route path="/layanan/:page" exact element={<Layanan />} />
        <Route path="*" element={<NotFound />} />
        {/* <Route path="/produk/etf" exact element={<Etf />} />
        <Route path="/produk/margin" exact element={<Margin />} />
        <Route path="/produk/reksadana" exact element={<Reksadana />} />
        <Route path="/produk/syariah"  element={<><Syariah /></>} />
        <Route path="/produk/saham" exact element={<Saham />} /> */}
        {/* <Route path="/titit" exact element={<>halo</>} /> */}
      </Routes>
      <WaLogo />
      <Footer />
    </Router>
  );
};
const RedirectUrl = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null;
};

function External({ link }) {
  console.log("eks");
  window.location.href = link;
  return (
    <>
      <div class="center-vertical" style={{ height: "80vh", width: "100vw" }}>
        <img
          src={redirect}
          class="mx-auto center-vertical"
          style={{ height: "9rem" }}
        ></img>
      </div>
    </>
  );
}
export default App;
