import "./style.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
export default function PageLocation(props) {
  // const locationObject = useParams();
  // const page = locationObject["*"];

  const complete_location = useLocation();
  const location = complete_location.pathname;
  let temp_arr = [];
  let temp = "";
  for (let i = 0; i < location.length; i++) {
    const element = location[i];

    if (element !== "/") {
      temp += element;
    }

    if (element == "/" && temp.length > 0) {
      // console.log(temp);
      temp_arr.push(temp);
      temp = "";
    } else if (i == location.length - 1) {
      temp_arr.push(temp);
    } else if (element == "/" && temp.length == 0) {
      continue;
    }
  }
  const current_location = temp_arr[1];
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    // console.log('dinav');
    // console.log(current_location, temp_arr);
    // if (page !== undefined && page !== "") {
    // console.log(document.getElementsByClassName("subPath"));
    //   let test;
    //   // console.log(document.getElementById(page), test);
    //   test = document.getElementById(page);
    //   document.getElementById(page).className += " subPathActive";
    // }
    // console.log(page);
    let allSubPath = document.getElementsByClassName("subPath");
    for (let i = 0; i < allSubPath.length; i++) {
      const element = allSubPath[i];
      // console.log(element.className);
      if (element.className !== current_location) {
        element.className = "subPath monsterratMedium rounded-pill mx-3";
      }
    }
    if (document.getElementById(current_location)) {
      document.getElementById(current_location).className += " subPathActive";
    }

    if (window.innerWidth > 768) {
      if (offset > 0) {
        document.getElementById("subPath").style.paddingTop = "0rem";
        document.getElementById("subPath").style.paddingBottom = "1rem";
        document.getElementById("navBar").style.boxShadow = "none";
        document.getElementById("subPath").style.boxShadow =
          "0px 4px 14px rgb(0 0 0 / 7%)";
      }
      if (offset == 0) {
        if (window.innerWidth > 768) {
          document.getElementById("subPath").style.paddingTop = "5rem";
        }
        document.getElementById("subPath").style.boxShadow = "none";
        document.getElementById("navBar").style.boxShadow =
          "0px 4px 14px rgb(0 0 0 / 7%)";
        document.getElementById("subPath").style.paddingBottom = "0rem";
      }
    }

    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [offset, current_location]);
  const test = useLocation();
  // console.log(offset);
  const navigate = useNavigate();
  const goTo = (route) => {
    // console.log("masuk");
    navigate(route);
  };

  const productList = [
    { name: "Akun Reguler", id: "saham", path: "/produk/saham" },
    { name: "Akun Margin", id: "margin", path: "/produk/margin" },
    { name: "Akun Syariah", id: "syariah", path: "/produk/syariah" },
    { name: "Reksa Dana", id: "reksadana", path: "/produk/reksadana" },
    { name: "Etf", id: "etf", path: "/produk/etf" },
    {
      name: "Waran Terstruktur",
      id: "waran-terstruktur",
      path: "/produk/waran-terstruktur",
    },
  ];
  return (
    <div>
      <div className="pageLocationWrapper ">
        {props.leftPath ? (
          <div className="path monsterratMedium mt-3 px-4 mx-5">
            <div
              className="leftSide"
              onClick={() => {
                goTo(props.leftPath);
              }}
            >
              {props.left}
            </div>
            <div className="divider" style={{ fontWeight: "900" }}>
              &nbsp;&nbsp;|&nbsp;&nbsp;
            </div>
            <div className="rightSide">{props.right}</div>
          </div>
        ) : null}
      </div>

      <div id="subPath" className="subPathWrapper  productSubPath fixed-top">
        <div className="subPathContainer">
          <div
            className="subPath subPathProduk monsterratMedium rounded-pill "
            style={{}}
            id="saham"
            onClick={() => goTo("/produk/saham")}
          >
            Akun Reguler
          </div>
          <div
            className="subPath subPathProduk monsterratMedium rounded-pill "
            style={{}}
            id="margin"
            onClick={() => goTo("/produk/margin")}
          >
            Akun Margin
          </div>
          <div
            className="subPath subPathProduk monsterratMedium rounded-pill "
            style={{}}
            id="syariah"
            onClick={() => goTo("/produk/syariah")}
          >
            Akun Syariah
          </div>
          <div
            className="subPath subPathProduk monsterratMedium rounded-pill "
            style={{}}
            id="reksadana"
            onClick={() => goTo("/produk/reksadana")}
          >
            Reksa Dana
          </div>
          <div
            className="subPath subPathProduk monsterratMedium rounded-pill "
            style={{}}
            id="etf"
            onClick={() => goTo("/produk/etf")}
          >
            ETF
          </div>
          <div
            className="subPath subPathProduk monsterratMedium rounded-pill "
            style={{}}
            id="waran-terstruktur"
            onClick={() => goTo("/produk/waran-terstruktur")}
          >
            Waran Terstruktur
          </div>{" "}
          {/* <div
            className="subPath subPathProduk monsterratMedium rounded-pill "
            style={{}}
            id="bonds"
            onClick={() => goTo("/produk/bonds")}
          >
            Bonds
          </div> */}
        </div>
      </div>

      <div>
        <select
          onChange={(e) => console.log(navigate(e.target.value))}
          className="form-select subPathSelect monsterratMedium f12r"
          aria-label="Default select example"
        >
          {productList.map((product, i) => {
            if (current_location == product.id) {
              return (
                <option selected className="subPathOption" value={product.path}>
                  {product.name}
                </option>
              );
            } else {
              return (
                <option className="subPathOption" value={product.path}>
                  {product.name}
                </option>
              );
            }
          })}
        </select>
      </div>
    </div>
  );
}
